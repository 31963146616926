//Base
$white: #ffffff;
$green: #7ED321;
$black: #000000;
$grey: #BBBBBB;
$grey-70: #5C5D60;
$blue: #96BFF2;
$red: #F29696;
$yellow: #FCDA00;
$pink: #FD267D;
$orange: #FF7854;
$purple: #9747FF;
$brown: #613813;

//Background
$background-1: #111317;
$background-2: lighten($background-1, 3%);
$background-3: lighten($background-1, 5%);

//Foreground
$foreground-1: $white;
$foreground-2: $grey;

$color_primary: $pink;
$color_secondary: $blue;

// GRADIENTS
$gradient-water--1: linear-gradient(to right, #EDD575, #39EEF3);
$gradient-water--2: linear-gradient(to right, #2CD5C4, #C5E86C);

// BRANDING
$wm-blue: #0470DC;
$publix-primary: #2d810e;
$publix-secondary: #004b2a;
$publix-tertiary: #f1f7cc;
$tropicana-primary: #007e47;
