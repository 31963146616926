///////////////////////////////
/// ANIMATION HELPERS
///////////////////////////////

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

///////////////////////////////
/// ANIMATIONS
///////////////////////////////

@include keyframes(hamburger-top-in) {
  0% {
    @include rotate(0);
    top: -6px;
  }
  50% {
    @include rotate(0);
    top: 0;
  }
  100% {
    @include rotate(45);
    top: 0;
  }
}

@include keyframes(hamburger-top-out) {
  0% {
    @include rotate(45);
    top: 0;
  }
  50% {
    @include rotate(0);
    top: 0;
  }
  100% {
    @include rotate(0);
    top: -6px;
  }
}

@include keyframes(hamburger-bottom-in) {
  0% {
    @include rotate(0);
    bottom: -6px;
  }
  50% {
    @include rotate(0);
    bottom: 0;
  }
  100% {
    @include rotate(-45);
    bottom: 0;
  }
}

@include keyframes(hamburger-bottom-out) {
  0% {
    @include rotate(-45);
    bottom: 0;
  }
  50% {
    @include rotate(0);
    bottom: 0;
  }
  100% {
    @include rotate(0);
    bottom: -6px;
  }
}

@mixin slide($startVal, $endVal) {
  @include animation('slide 0.5s 1');

  @include keyframes(slide) {
    0% { height: $startVal; }
    100% { height: $endVal; }
  }
}

@mixin fade($startVal, $endVal, $duration) {
  @include animation('fade ' + $duration + 's' + ' ease-in');

  @include keyframes(fade) {
    0% { opacity: $startVal; }
    100% { opacity: $endVal; }
  }
}

@mixin hamburger-rotate-top() {
  @include keyframes(hamburger-top) {
    0% {
      @include rotate(0);
      top: -6px;
    }
    50% {
      @include rotate(0);
      top: 0;
    }
    100% {
      @include rotate(45);
      top: 0;
    }
  }
}

@mixin hamburger-rotate-bottom() {
  @include keyframes(hamburger-bottom) {
    0% {
      @include rotate(0);
      bottom: -6px;
    }
    50% {
      @include rotate(0);
      bottom: 0;
    }
    100% {
      @include rotate(-45);
      bottom: 0;
    }
  }
}

@keyframes App-logo-spin {
  from { @include transform(rotate(0deg)); }
  to { @include transform(rotate(360deg)); }
}

@include keyframes(loading-pacman-1) {
  0% {
    @include rotate(0);
  }
  50% {
    @include rotate(-45);
  }
  100% {
    @include rotate(0);
  }
}

@include keyframes(loading-pacman-2) {
  0% {
    @include rotate(180);
  }
  50% {
    @include rotate(225);
  }
  100% {
    @include rotate(180);
  }
}

@include keyframes(loading-pacman-3) {
  0% {
    @include transform(translate(190px, 0));
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    @include transform(translate(70px, 0));
    opacity: 1;
  }
}

@include keyframes(slider-hint) {
  0% {
    margin-left: 0px;
  }
  5% {
    margin-left: -8px;
  }
  10% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@include keyframes(reveal) {
  0% {
    @include translate3d(0, -100%, 0);
  }
  100% {
    @include translate3d(0, 0, 0);
  }
}

@include keyframes(unreveal) {
  0% {
    @include translate3d(0, 0, 0);
  }
  100% {
    @include translate3d(0, -100%, 0);
  }
}

@include keyframes(cloud-1) {
  0% {
    @include translate3d(100%, -50%, 0);
  }
  100% {
    @include translate3d(0, -50%, 0);
  }
}

@include keyframes(cloud-2) {
  0% {
    @include translate3d(-100%, -50%, 0);
  }
  100% {
    @include translate3d(0, -50%, 0);
  }
}

@include keyframes(stars) {
  0% {
    opacity: 0;
  }
  20% {
    opacity: .8;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: .8;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@include keyframes(bounce) {
  0%   {
    @include transform(scale(1,1) translateY(0));
  }
  10%  {
    @include transform(scale(1.1,.9) translateY(0));
  }
  30%  {
    @include transform(scale(.9,1.1) translateY(-8px));
  }
  50%  {
    @include transform(scale(1.05,.95) translateY(0));
  }
  57%  {
    @include transform(scale(1,1) translateY(-3px));
  }
  64%  {
    @include transform(scale(1,1) translateY(0));
  }
  100% {
    @include transform(scale(1,1) translateY(0));
  }
}

///////////////////////////////
/// TRANSITIONS
///////////////////////////////


@mixin transition($transition, $transition2:"") {
  @if($transition2 != "") {
    transition: $transition, $transition2;
    -moz-transition: $transition, $transition2;
    -webkit-transition: $transition, $transition2;
  } @else {
    transition: $transition;
    -moz-transition: $transition;
    -webkit-transition: $transition;
  }

}
