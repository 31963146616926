$sizeMobile: 125px;
$sizeDesktop: 175px;

.case-study {
  display: flex;
  gap: $base_padding-md;
  padding: $base_padding-lg;
  border-radius: 8px;
  background: transparent;
  border: 1px solid transparent;
  transition: background 0.3s ease, border 0.3s ease;

  @include breakpointLargerThan($breakpoint--medium) {
    gap: $base_padding-lg;
    height: 250px;
  }

  &:hover {
    background: rgba($white, 0.02);
    border: 1px solid #414141;
  }

  button {
    @extend a;
    border: none;
    box-shadow: none;
    background: none;
    width: fit-content;
    padding: 0;
    text-align: left;
    display: block;
  }

  //Left Section
  .case-study__left {
    flex: 0 0 $sizeMobile;
    height: $sizeMobile;
    display: flex;
    flex-direction: column;
    gap: $base_padding-sm;
    transition: all 0.3s ease;

    @include breakpointLargerThan($breakpoint--medium) {
      flex: 0 0 $sizeDesktop;
      height: $sizeDesktop;
    }

    h3 {
      font-size: $base_font-size;
      font-weight: 500;
      margin: 0;
    }

    .case-study__company {
      color: $grey;
      letter-spacing: 2px;
    }

    .case-study__image {
      width: $sizeMobile;
      height: $sizeMobile;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
      display: none;

      @include breakpointLargerThan($breakpoint--medium) {
        width: $sizeDesktop;
        height: $sizeDesktop;
      }
    }
  }

  // Right Section
  .case-study__right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $base_padding-md;

    .case-study__description {
      color: $grey;
      margin-bottom: $base_padding-sm;
    }

    .case-study__links {
      display: flex;

      @include breakpointLargerThan($breakpoint--medium) {
        display: none; // Hidden by default
      }

      flex-direction: column;
      gap: $base_padding-sm;
    }
  }

  // Hover State Adjustments
  &:hover {
    .case-study__left {
      h3,
      .case-study__company {
        display: none;
      }

      .case-study__image {
        display: block;
      }
    }

    .case-study__right {
      .case-study__links {
        display: flex;
      }
    }
  }
}

// MODAL

.case-study-modal {
  img {
    width: 100%;
    margin: $base_padding-lg 0;
  }

  h3 {
    font-size: $base_font-size * .9;
    color: $grey;
    font-weight: 400;
    letter-spacing: 1px;
    margin: $base_padding-sm 0 $base_padding-md 0;
  }
}
