.site-footer {
  padding: $base_padding-lg 0;
  text-align: center;

  span {
    display: block;
    margin: 10px 0;
  }
}

.site-footer__text{
  color: $grey-70;
}
