.navbar {
  display: flex;
  gap: $base_padding-md;
  align-items: center;
  justify-content: center;
  margin: $base_padding-xxlg 0;
}

.nav-link {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  color: rgba($foreground-1, 0.7);
  letter-spacing: 1px;
}

.nav-link:hover {
   background-color: rgba($color_secondary, 0.19);
   color: $color_secondary;
}

.nav-link.active {
  background-color: rgba($color_primary, 0.19);
  color: $color_primary;
}
