.button {
  font-weight: 400;
  padding: $base_padding-md $base_padding-lg;
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  position: relative;
  width: fit-content;
}

.button-primary {
  $_background: $white;
  $_foreground: $black;

  @extend .button;
  @include borderRadius(99px);
  background: $_background;
  color: $_foreground;

  &:hover, &:focus {
    background: darken($_background, 10%);
    color: $_foreground;
  }
}

.button-ghost {
  $_background: $background-3;

  @extend .button-primary;
  background: $_background;
  color: $white;

  &:hover, &:focus {
    background: darken($_background, 6%);
    color: $white;
  }
}

//Outline Animation Button (Not Used RN)
.button-fancy {
  $foregroundColor: $white;
  $hoverColor: $color_primary;
  $backgroundColor: inherit;
  $distance: 6px;
  $border: 2px solid $foregroundColor;

  @include transition(all .1s cubic-bezier(0.3, 0, 0.45, 1));
  @extend .button;

  background: $backgroundColor;
  color: $foregroundColor;
  border: 2px solid $foregroundColor;

  &:before, &:after {
    @include transition(.1s cubic-bezier(0.3, 0, 0.45, 1));
    content: "";
    display: block;
    position: absolute;
  }

  &:before {
    width: 100%;
    border-bottom: $border;
    border-left: $border;
    bottom: -$distance;
    left: $distance;
    height: $distance;
    box-sizing: border-box;
  }

  &:after {
    height: 100%;
    border-right: $border;
    border-top: $border;
    top: $distance;
    right: -$distance;
    width: $distance;
    box-sizing: border-box;
  }

  &:hover {
    color: $hoverColor;
    border-color: $hoverColor;
    fill: $hoverColor;

    &:before {
      bottom: 0px;
      left: 0px;
      opacity: 0;
    }
    &:after {
      top: 0;
      right: 0;
      opacity: 0;
    }
  }
}
