.header {
  padding: $base_padding-lg;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  height: 100px;
}

.header__logo {
  @include centerAll();
  position: absolute;

  svg {
    @include transition(all 200ms);
    width: 170px;
  }

  &:hover {
    svg {
      @include scale(1.1);
    }
  }

  &:active {
    //fix a position issue
    @include centerAll();
  }
}

.header__nav {
  @include breakpointSmallerThan($breakpoint--medium) {
    display: none;
  }

  float: right;

  a {
    color: $grey;
    margin-left: $base_padding-lg;
  }
}
