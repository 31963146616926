.quote {
  padding: $base_padding-xxlg;
  background: $background-2;
  margin: 0;
}

.quote__content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
}

.quote__image {
  @include borderRadius(99px);
  @include boxShadow(0 0 16px rgba(0,0,0,0.1));
  width: 100px;
  height: 100px;
  margin-right: $base_padding-lg;
}

.quote__text {
  @include font-size($base_font-size * 1.2);
  padding: 0;
  margin: 0;
}

.quote__name {
  @include font-size(12px);
  font-style: italic;
  color: $foreground-2;
}
