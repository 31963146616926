.hero {
  $highlightColor: $color_primary;

  display: flex;
  flex-direction: column;
  gap: $base_padding-md;
  margin-top: $base_padding-xxlg;

  a {
    text-decoration: none;

    &::before {
      @include transition(100ms all ease-in);
      @include rotate(-1);
      @include borderRadius(2px);

      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 8px;
      background: rgba($highlightColor, 0.19);
      left: 0;
      bottom: 3px;
      z-index: -1;
    }

    &:hover {
      background: none;
      color: $background-1;

      &::before {
        @include rotate(0);
        height: 100%;
        bottom: 0;
        background: $highlightColor;
      }
    }
  }
}

.hero h1 {
  font-size: $base_font-size * 1.5;
  font-weight: 500;
}

.hero p {
  font-size: $base_font-size * 1.25;
}
