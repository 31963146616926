.password {
  @include centerAll();
  position: absolute;
  width: 450px;

  input {
    width: 100%;
  }
}

.form__button {
  @extend .button;
  margin-top: 24px;
  cursor: pointer;
}
