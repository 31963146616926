.modal-overlay {
  @include backgroundBlur(3px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: $background-2;
  border-radius: 8px;
  padding: $base_padding-lg;
  max-width: 600px;
  width: 90%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.5rem;
  color: $foreground-1;
  background: none;
  border: none;

  &:hover {
    color: $red;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: $base_padding-lg;
  gap: $base_padding-md;
}
