body {
  background-color: $background-1;
  color: $white;
  @include font-size($base_font-size);
  font-family: $primary-font !important;
  margin: 0;
  padding: 0;
}

h1 {
  @include font-size(calc($base_font-size * 2));
  font-family: $primary-font;
  font-weight: 400;
  margin: 0;
  padding: 0;
  @include breakpointLargerThan($breakpoint--large) {
    @include font-size(48px);
  }
}

h2 {
  @include font-size(calc($base_font-size * 1.5));
  font-family: $primary-font;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h3 {
  @include font-size(calc($base_font-size));
  font-family: $primary-font;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

h4 {
  @include font-size($base_font-size / 1.25);
  font-family: $primary-font;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

h5 {
  @include font-size(calc($base_font-size / 1.5));
  font-family: $primary-font;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

p {
  @include font-size($base_font-size);
  font-family: $primary-font;
  line-height: 1.65;

  span[role="img"] {
    color: $white;
  }
}

//Rock On Cursor
a, button {
  cursor: url($assets-path + 'cursors/rockon.cur'), pointer !important;
}

a, a:not([href]):not([class]), a:not([href]):not([class]):hover {
  outline: none;
  position: relative;
  color: inherit;
  text-decoration: underline;
  padding: 3px;

  &:focus {
    color: inherit;
  }

  &:active {
    top: auto;
  }

  &:hover {
    color: $color_primary;
  }
}

button {
  border: none;
  box-shadow: none;
}

ol {
  @extend p;
}

img {
  width: 100%;
}

caption {
  display: block;
}

header {
  margin-bottom: $base_padding-md;
}

.video {
  width: 100%;
}

input {
  color: $foreground-1;
  padding: $base_padding-md;
  background-color: $background-1;
  border: 1px solid $foreground-2;
}
